/* Seeq branding */

$sq-report-color: #39516b;
$sq-analysis-color: #427c63;
$sq-datalab-color: #f26722;

$sq-text-on-gray: #000000;

$white: #ffffff;
$black: #000000;
$red: #f00;

$sq-darker-primary-topic: #12385b;
$sq-dark-primary-topic: #2a5c84;
$sq-link-color-topic: #2a5c84;
$sq-icon-over-topic: #0491c2;
$sq-highlight-topic: #EEF6FD;
$sq-light-color-topic: #EDF7F6;

$sq-darker-primary-analysis: #124734;
$sq-dark-primary-analysis: #007960;
$sq-link-color-analysis: #007960;
$sq-icon-over-analysis: #00a059;
$sq-icon-activated-analysis: #00cb72;
$sq-icon-activated-topic: #41a5e1;
$sq-highlight-analysis: #EEFEF5;
$sq-light-color-analysis: #EDF7F6;

$sq-darker-primary-datalab: #C1480B;
$sq-dark-primary-datalab: #F26722;
$sq-link-color-datalab: #F26722;
$sq-icon-over-datalab: #F47B3E;
$sq-highlight-datalab: #EDF7F6;
$sq-light-color-datalab: #EDF7F6;

$sq-darker-gray: #e7e7e7;
$sq-disabled-gray: #DDE1E3;
$sq-darkest-gray: #e6e7e8;
$sq-darkish-gray: #b7b7b7;
$sq-dark-gray: #E2E2E2;
$sq-light-gray: #f1f5f7;
$sq-lightest-gray: #f1f5f7;
$sq-overlay-gray: #EEEDEE;
$sq-side-panel-text-color: #999999;
$sq-text-color: #3a3a3a;
$sq-fairly-dark-gray: #606061;
$sq-gray-text: #869298;
$sq-table-highlight: #f9f9f9;
$sq-backdrop: #c0c0c008;
$sq-worksheetspanel-gray: #f1f3f4;

$sq-status-error-color: #D9534F;
$sq-status-progress-color: #3a3a3a;
$sq-status-warning-color: #F0AD4E;
$sq-status-good-color: #0c684c;

$sq-success-color: #72c02c;
$sq-active-color: #fcb426;
$sq-text-warning-color: #F0AD4E;
$sq-text-dark-warning-color: #664D03;
$sq-danger-color: #D9534F;
$sq-bg-success-color: #dff0d8;
$sq-bg-warning-color: #fff3cd;
$sq-bg-danger-color: #f2dede;
$sq-bg-warning-border-color: #FFECB5;

$backgroundcolor-trace1: #DBFFFF;
$backgroundcolor-trace2: #CEFDFF;
$backgroundcolor-debug1: #F4FFFF;
$backgroundcolor-debug2: #E7FFFF;
$backgroundcolor-info1: white;
$backgroundcolor-info2: #F8F8F8;
$backgroundcolor-warn1: #FFFFE8;
$backgroundcolor-warn2: #FFFFDB;
$backgroundcolor-error1: #FFEDE9;
$backgroundcolor-error2: #FFE0DC;
$backgroundcolor-block: #F8F8F8;
$backgroundcolor-selected: #E5ECFB;
$backgroundcolor-hover: #FBCF7C;
$backgroundcolor-table-highlight-green: #eef8f4;
$backgroundcolor-table-highlight: #fcf8e3;
$backgroundcolor-table-highlight-hover: #faf2cc;
$backgroundcolor-highlight: darken($backgroundcolor-table-highlight, 10%);
$backgroundcolor-highlight-hover: darken($backgroundcolor-table-highlight-hover, 10%);
$bordercolor: $sq-darkest-gray;
$bordercolor-selected: darken($backgroundcolor-selected, 10%);
$bordercolor-hover: darken($backgroundcolor-hover, 10%);

$sq-light-thin-border: 1px solid rgba($sq-light-gray, 0.2);

$box-border-radius: 0px;
$screen-md-height: 850px;

/**
These are the "Theme Colors" used to visually differentiate the Analysis and Topic displays.
The $themeColors map is used by themeColors.scss to create theme specific css.
The "dark" color is the color of the side panel and header bar,
the "darker" color is hardly used (only to accentuate the Seeq logo in the left corner and some borders)
the "icon" color is used on hover of icons
the "light" color is only used for the icons in the header bar.
 **/
$themeColors: (
  analysis: (
    darker: $sq-darker-primary-analysis,
    dark: $sq-dark-primary-analysis,
    icon: $sq-icon-over-analysis,
    light: $sq-light-color-analysis,
    link: $sq-link-color-analysis,
    highlight: $sq-highlight-analysis
  ),
  topic:(
    darker: $sq-darker-primary-topic,
    dark: $sq-dark-primary-topic,
    icon: $sq-icon-over-topic,
    light: $sq-light-color-topic,
    link: $sq-link-color-topic,
    highlight: $sq-highlight-topic
  ),
  datalab:(
    darker: $sq-darker-primary-datalab,
    dark: $sq-dark-primary-datalab,
    icon: $sq-icon-over-datalab,
    light: $sq-light-color-datalab,
    link: $sq-link-color-datalab,
    highlight: $sq-highlight-datalab
  )
);

$fixed-header-table-z-index: 100;

/******************* Report Editor *******************/
.reportDateModal {
  .editableTextInput {
    background: none;
  }

  .displayRangeSelector {
    .displayRangeDateTime, #displayRangeNavigation {
      font-size: 15.6px;
    }

    div > .displayRangeDateTime > div {
      border-radius: 5px;
      padding: 5px;
    }

    // Prevent start range from shifting left when switched to readonly mode
    div#displayRangeStart > .durationTimeEntry > div > span.readOnly:first-of-type {
      margin-left: 7px;
    }

    // Center time VCR controls between start and end date entry fields
    #displayRangeNavigation {
      margin-left: 10px;
      vertical-align: middle;
    }

    #displayRangeNavigation > .durationTimeEntry {
      border-radius: 3px;
      padding: 5px;
    }

    #conditionEditor {
      ul.dropdown-menu {
        width: 100%;
      }
    }
  }

  // Limit height of condition search UI
  .functionParamsSearchResults {
    max-height: 400px;
  }

  .capsulesPanel > div {
    display: block; // Otherwise IE11 hides the capsules table
  }

  .alert-info {
    border-radius: 0;
  }

  form {
    margin-bottom: 0;
  }

  .capsulesPanel {
    max-height: 300px;
  }

  input[type="checkbox"] {
    margin: 0 0 0;
  }
}

.seeqContentDropdown {
  padding: 0 24px 10px !important;
  line-height: 200% !important;
  cursor: pointer !important;
  text-decoration: none !important;
  font-size: 15px !important;

  & > div {
    line-height: 90% !important;
    font-size: 75% !important;
  }
}

.templateThumbnailContainer {
  position: relative;

  padding: 5px;
  border-radius: 5px;
  text-align: center;

  img {
    min-height: 80px;
    border: 1px solid $sq-disabled-gray;
  }
}

.report-content-modal, .items-from-worksheet-modal {
  min-width: 650px !important;
  max-width: 650px !important;
  width: 650px !important;

  .report-worksheets-list {
    padding: 10px;
    overflow-y: auto;
    min-height: 393px;
    max-height: 393px;
    border-radius: 4px;
  }

  .worksheetContainer {
    padding: 5px;
    border-radius: 5px;

    img {
      min-height: 80px;
    }
  }

  .reportContentUrl {
    textarea {
      border-radius: 3px;

      &[placeholder] {
        text-align: center;
        padding-top: 15px;
        resize: none;
      }
    }
  }

  .modalSeparation {
    h5 {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid lightgrey;
      line-height: 0.1em;
      margin: 10px 0 20px;
    }

    h5 span {
      background: #fff;
      padding: 0 10px;
    }
  }

  .reportContentProperties {
    @extend .flexFillOverflow;

    .image, .placeholder {
      min-height: 272px;
      max-height: 700px;
      border-top-left-radius: 3px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .properties {
      border-bottom-left-radius: 3px;
    }

    .settings {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.bulkEditProperties {
  @extend .flexFillOverflow;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  min-width: 220px;
}

.bulkEditTable {
  border-radius: 3px;
  margin-bottom: 0;
  overflow: auto;
  height: 550px;
}

.blackShape {
  border: 1px solid black;
}

.report-image-border {
  border: 1px solid lightgrey;
}

.fr-view img.report-no-margin {
  margin: 0;
}

.reportBackups {
  div[uib-accordion-group] {
    overflow: hidden;
    margin: -4px;
    box-shadow: none;

    &.card {
      border-radius: 4px;
    }

    .card-header {
      margin-bottom: 0 !important;
      background-color: $white;
      padding: 6px 10px 6px 5px;

      .flexColumnContainer {
        color: $sq-link-color-topic;
        cursor: pointer;
      }
    }

    .card-body {
      border-top: 1px solid #ddd;
      padding: 0 0 0 5px;
    }

    .card-header {
      padding-left: 5px;

      h4 {
        padding: 0;
        margin: 0;
        font-size: 13px;
      }
    }

    .card-body {
      padding: 0;

      .versionEntry {
        cursor: pointer;
        padding-left: 5px;

        position: relative;

        .clickPreviewPrompt {
          display: block;
          position: absolute;
          right: 7px;
          top: 9px;
          font-size: 11px;
          font-style: italic;
        }

        .versionEntryUser {
          font-size: 11px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.stopUpdateBackground {
  background: white center no-repeat url('../../public/img/loading-pulse.gif');
}

/* TODO (CRAB-15124) - The min-height here is necessary for the spinner to be sized correctly, but also applies to content previews, which makes them look funky. */
.loadingSpinner {
  background: white center no-repeat url('../../public/img/loading-pulse.gif');
  border: 1px solid lightgrey;
  min-width: 50px;
  min-height: 50px;
}

.loadingSpinnerNoBorder {
  @extend .loadingSpinner;
  border: 0px;
}

.contentLoadError {
  background: white center no-repeat url('../../public/img/error.png');
  //Minimum dimensions are needed for tables because there are no dimensions for the div to inherit
  //The dimensions of the background icon are 30px x 30px.
  min-width: 30px;
  min-height: 30px;
  border: 1px solid lightgrey;
}

.contentNoCapsuleError {
  background: white center no-repeat url('../../public/img/fa-calendar-times.png');
  border: 1px solid lightgrey;
}

.contentPending {
  @extend .loadingSpinner;
  background: white center no-repeat url('../../public/img/arc-spinner.gif');
}

div.reportBackupPreview {
  height: 75px;
}

.contentWrapperStopUpdate {
  visibility: hidden;
  pointer-events: none;
}

/******************* Journal Editor *******************/
.breakLongWords {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word; // Non-standard but works
}

.anchorButtonBorder {
  padding: 0 2px;
  border-radius: 3px;
}

.journalHeaderDropdown i.fc-annotate {
  transform: translate(0, 2px);
}

input#discoverable {
  margin: 0;
}

.journalFooter .discoverableDescription {
  line-height: 100%;
  font-size: 82%;
}

// CRAB-22106 - In order for the cleanup job added in this CRAB to be safe, we need to ensure that no annotation
// document refers to
.crossReferencedImage {
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), center no-repeat url('../../public/img/error.png');
  background-size: 30%;

  > img {
    position: relative !important;
    z-index: -1;
  }
}

@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: transparent) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  padding-right: 1em;
  background-color: inherit;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

.journal-card-overflow {
  @include multiLineEllipsis($lineHeight: 1em, $lineCount: 3, $bgColor: inherit);
}

.noValidationIcons.form-control.is-invalid {
  background-image: none;
  padding-right: 0;
}

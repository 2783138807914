$timebar-control-height: 32px;
$border-stroke-width: 2px;
$border-color: $sq-disabled-gray;
$selector-border-color: $sq-darkest-gray;
$selector-fill-color: white;
$selector-outer-color: #EAF3F4;
$text-color: $sq-side-panel-text-color;

.timebarWrapper {
  flex: 1 1 auto;
  margin: 0;
  height: 30px;
  margin-bottom: 0;
  position: relative;

  .outer {
    border: $border-stroke-width solid $border-color;
    background-color: white;
    height: 14px;
    flex: 1 1 auto;
    border-radius: 0px;
    position: relative;
    z-index: 1;

    .moveSelectorAria {
      position: absolute;
      left: 0;
      right: 0;
      height: 12px;
      z-index: 2;
    }
  }

  .timebarContainer {
    z-index: 5;

    .rangeIndicator {
      display: flex;
      margin-top: -2px;
    }

    .leftSelector,
    .rightSelector {
      cursor: ew-resize !important;
      width: 5px !important;
      border: 2px solid $sq-link-color-analysis;
      background-color: white;
      height: 18px !important;
      margin-top: -4px;
    }

    .selector {
      border: 2px solid $sq-link-color-analysis;
      border-left: 0;
      border-right: 0;
      margin-top: 0px;
      height: 14px;
      width: 100%;
      cursor: pointer;
      background-color: white;

      &.capsulePreview {
        background-color: transparent;
      }
    }
  }

  .regions {
    flex: 1 1 auto;
    position: relative;
    z-index: 4;

    .region {
      position: absolute;
      height: 10px;
    }
  }

  .ticksContainer {
    height: 25px;
    top: -1px;
    left: 0px;
    position: absolute;
    right: 0;

    .highcharts-series-group {
      display: none;
    }

    .highcharts-axis-line {
      display: none;
    }

    .highcharts-root {
      font-family: inherit !important;
    }

    .highcharts-axis-labels {
      text {
        fill: $sq-gray-text !important;
      }
    }
  }
}
